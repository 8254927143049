"use client";

import React, { Fragment, useState, FC, useMemo, ReactNode } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { get, set } from "lodash";

import { calculateNestedKeys } from "helpers/calculate";
import { toClassName } from "helpers/format";
import { deepCopyObject } from "helpers/object";

import { TSlideOverOverlayStyles, styles as defaultStyles } from ".";

export type TProps = {
  trigger?: ({
    handleOpen,
    handleClose,
  }: {
    handleOpen: () => void;
    handleClose: () => void;
  }) => ReactNode;
  children: ({
    handleOpen,
    handleClose,
  }: {
    handleOpen: () => void;
    handleClose: () => void;
  }) => ReactNode;

  size?: "xs" | "sm" | "md" | "lg" | "xl";

  styles?: TSlideOverOverlayStyles;
};

const SlideOverOverlay: FC<TProps> = (props) => {
  const { children, trigger, size = "md", styles: stylesOverrides } = props;

  const [isOpen, setIsOpen] = useState(trigger ? false : true);

  const options = useMemo(
    () => ({
      handleOpen: () => setIsOpen(true),
      handleClose: () => setIsOpen(false),
    }),
    [],
  );

  const handleGenerateStyle = () => {
    const result = deepCopyObject(defaultStyles.base);
    const keys = calculateNestedKeys(defaultStyles.base);

    keys.forEach((key) => {
      set(
        result,
        key,
        toClassName(
          get(defaultStyles.base, key),
          size && get(defaultStyles[size], key),
          get(stylesOverrides, key),
        ),
      );
    });

    return result;
  };

  const styles = handleGenerateStyle();

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className={styles.container} onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter={styles.background.transition.enter}
            enterFrom={styles.background.transition.enterFrom}
            enterTo={styles.background.transition.enterTo}
            leave={styles.background.transition.leave}
            leaveFrom={styles.background.transition.leaveFrom}
            leaveTo={styles.background.transition.leaveTo}
          >
            <div className={styles.background.container} />
          </Transition.Child>

          <div className={styles.layout.container}>
            <div className={styles.layout.content}>
              <div className={styles.layout.inner}>
                <Transition.Child
                  as={Fragment}
                  enter={styles.panel.transition.enter}
                  enterFrom={styles.panel.transition.enterFrom}
                  enterTo={styles.panel.transition.enterTo}
                  leave={styles.panel.transition.leave}
                  leaveFrom={styles.panel.transition.leaveFrom}
                  leaveTo={styles.panel.transition.leaveTo}
                >
                  <Dialog.Panel className={styles.panel.container}>
                    {children(options)}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {trigger && trigger(options)}
    </>
  );
};

export default SlideOverOverlay;
