import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/cookie-consent/cookie-consent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/custom/Navbar/partials/ClientProxy/ClientProxy.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/components/tailwind-ui/Button/Button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/providers/posthog/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
