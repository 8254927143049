"use client";

import React, { FC, useState } from "react";

import { handleFixImageUrl } from "helpers";

import { toClassName } from "helpers/format";

import { APIResponseData } from "types/models";

import BlockRendererClient from "components/strapi/BlockRendererClient";
import Icon from "components/tailwind-ui/Icon";

import { styles } from ".";

type TProps = {
  service: APIResponseData<"api::service.service">;
  isAbleToCollapse?: boolean;
  isOpen?: boolean;
};

const ServiceItemCustom: FC<TProps> = (props) => {
  const {
    service,
    isAbleToCollapse = true,
    isOpen: isDefaultOpen = false,
  } = props;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  return (
    <div
      className={toClassName(
        styles.container,
        service?.attributes?.url && "group cursor-pointer",
        isAbleToCollapse && "cursor-pointer",
      )}
      onClick={
        service?.attributes?.url
          ? () => window.open(service?.attributes?.url)
          : isAbleToCollapse
            ? () => setIsOpen(!isOpen)
            : () => null
      }
    >
      <div className={styles.head}>
        <div
          className={toClassName(styles.image, "!h-8")}
          style={{
            backgroundImage: `url(${handleFixImageUrl(service?.attributes?.image?.data?.attributes?.url ?? "")})`,
          }}
        />
      </div>
      <div
        className={toClassName(styles.foot, isAbleToCollapse && "w-full pt-2")}
      >
        <div
          className={toClassName(
            isAbleToCollapse && "flex justify-between w-full items-center",
          )}
        >
          <p
            className={toClassName(
              styles.heading,
              service?.attributes?.url &&
                "text-primary-600 group-hover:underline",
              isAbleToCollapse && "!text-xs",
            )}
          >
            {service?.attributes?.title}
          </p>
          {!service?.attributes?.url && (
            <Icon
              accessor={isOpen ? "chevron-up" : "chevron-down"}
              className="text-xs"
            />
          )}
        </div>
        <div className="text-gray-700 [&>*]:!leading-5 [&>*]:!text-left pt-4  [&>*]:!text-xs">
          {isAbleToCollapse && isOpen && (
            <BlockRendererClient content={service?.attributes?.description} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceItemCustom;
